<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb id="breadcrumb-container" class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Data Analyst</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Data Analyst</p>
            <div class="description-container">
                <p class="subtitle">Job Descriptions</p>
                <ul class="requirement-list">
                    <li class="list-details"> Responding to data-related queries and keeping track of these</li>
                    <li class="list-details"> Analyzing data to identify trends</li>
                    <li class="list-details"> Setting up processes and systems to make working with data more efficient</li>
                    <li class="list-details"> Researching new ways to make use of data</li>
                    <li class="list-details"> Producing reports and charts communicating trends within data to non-specialists</li>
                    <li class="list-details"> Presenting information generated from data to managers.</li>
                </ul>
                <p class="salary">SALARY: NEGOTIABLE</p>
            </div>

            <div class="description-container">
                <p class="subtitle">Job Requirements</p>
                <ul class="requirement-list">
                    <li class="list-details"> Bachelor’s degree in data science or related degree</li>
                    <li class="list-details"> An understanding of the ethics of gathering and working with data</li>
                    <li class="list-details"> The ability to analyze, model and interpret data</li>
                    <li class="list-details"> Good command of English</li>
                    <li class="list-details"> The desired to work in a fast-paced environment</li>
                </ul>
            </div>
            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
  setup() {
    onMounted(() => {
      var jobDescriptionElement = document.getElementById('breadcrumb-container')
      jobDescriptionElement.scrollIntoView()
      console.log('This qill only run after initial render')
    })
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;
        .list-details {
            display: list-item;
            margin-bottom: 0.7%;
            list-style-type:disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}

.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
